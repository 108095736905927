/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

/*  */
@import "~@angular/cdk/overlay-prebuilt.css";
@import "node_modules/np-ui-lib/styles/default-theme.scss";
@import "./assets/styles/custom-timeline";


@import "swiper/scss";
@import "swiper/scss/navigation";
@import "swiper/scss/pagination";
/* Fuentes */

@font-face {
  font-family: Barlow;
  src: url("./assets/fonts/Barlow/Barlow-Regular.ttf");
  font-weight: normal;
}

@font-face {
  font-family: Barlow;
  src: url("./assets/fonts/Barlow/Barlow-Bold.ttf");
  font-weight: bold;
}

@font-face {
  font-family: Barlow;
  src: url("./assets/fonts/Barlow/Barlow-Italic.ttf");
  font-style: italic;
}



ion-card {
  box-shadow: 0px 2px 5px rgb(0 0 0 / 10%);
}

ion-content {
  background: #F5F5F5;
}

.tracking-map-modal {
  --height: 50%;
  --border-radius: 16px;
  --box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
  &::part(content) {
   
    top:25%;
    width: 85%;;
    height:50%;
} 
}