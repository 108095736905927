np-timeline {
  .np-timeline-container {
    margin-top: 3%;
    padding: 5% 0;
  }

  .np-timeline {
    border: none;

    .np-timeline-item-content {
      background: #FFF;
      border-radius: 10px;
      box-shadow: 5px 5px 5px 5px rgb(0 0 0 / 10%);

      h3 {
        font-size: 1rem !important;
        font-weight: bold;
      }

      .row {
        //display: flex;
        //flex-direction: row;
        //justify-content: start;
        .col-2 {
          padding-right: 12px;

          img {
            width: 60px;
            max-height: 150px;
          }
        }
      }
    }

    //.pending-step {
    //  .np-timeline-marker, .np-timeline-default-marker {
    //    background: #284C43;
    //    color: #284C43;
    //  }
    //}
    //
    //.success-step {
    //  .np-timeline-item-content{
    //    background: rgba(211, 211, 211, 0.16);
    //    opacity: 0.5;
    //  }
    //  .np-timeline-marker, .np-timeline-default-marker {
    //    background: rgba(241, 240, 240, 0.65);
    //
    //    color: #284C43;
    //  }
    //}

    .np-timeline-marker, .np-timeline-default-marker {
      width: 2rem;
      height: 2rem;

    }


    .np-timeline-connector {
      background-color: #284C43;
      width: 1px;
    }
  }
}
